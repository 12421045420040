import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Grid} from "@mui/material";

import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import {externalSourceTypeService} from "../../../api/services/externalSourceTypeService";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModal from "../../CustomModal/CustomModal";
import CustomModalBody from "../../CustomModal/CustomModalBody";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";

const schema = yup.object().shape({
    name: yup.string().required('Required field').min(1, 'Required'),
});

const AddExternalSourceTypeModal = ({setSnack, open, handleClose, handleRefreshItems}) => {
    const [loading, setLoading] = useState(false)
    const {
        control,
        handleSubmit,
        reset
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: ""
        }
    })
    const onSuccess = async (data) => {
        setLoading(true)

        externalSourceTypeService.createItem(data.name)
            .then((response) => {
                setSnack(response.message, "success")
                setLoading(false)
                reset({
                    name: ""
                })
                handleClose()
                handleRefreshItems()
            })
            .catch((error) => {
                setSnack("Could not Create", "error")
                setLoading(false)
            })

    }

    const onError = (error) => {
        // Handle Errors
        console.debug(error)
    }

    return (
        <CustomModal
            open={open}
            // onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            onSubmit={handleSubmit(onSuccess, onError)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Create External Source Type"}/>
            <CustomModalBody>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"name"} label={"Name"}/>
                    </Grid>
                </Grid>
            </CustomModalBody>
            <CustomModalFooter>
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Create External Source Type"}
                        loading={loading}
                    />
                </Grid>
            </CustomModalFooter>
        </CustomModal>

    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(AddExternalSourceTypeModal);