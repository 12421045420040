import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from 'qs';

const getItems = (
    args
) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        const data = {
            page: args.page,
            name: args.name,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            common: args.common,
            symbol: args.symbol
        }

        apiClient.get(endpoints.tag, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const createItem = (name, symbol, content) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        const data = {
            name,
            symbol,
            content
        };

        apiClient.post(endpoints.tag, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const updateItem = (tag_id, name, symbol, content) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        const data = {
            name,
            symbol,
            content
        };

        apiClient.patch(endpoints.tag + "/" + tag_id, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const deleteItem = ({itemId}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        apiClient.delete(endpoints.tag + "/" + itemId)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const deleteItems = (items) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            items_to_delete: Object.keys(items),
        }

        apiClient.post(endpoints.tag + "/delete-multiple", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const getTagsNotInDynamicTag = (
    args
) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            page: args.page,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            excluded_dynamic_tag_id: args.excluded_dynamic_tag_id,
            tag_name: args.tag_name,
        }

        apiClient.get(endpoints.tag + "/available", {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

export const tagService = {
    getItems,
    createItem,
    updateItem,
    deleteItem,
    deleteItems,
    getTagsNotInDynamicTag
};
