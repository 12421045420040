import React, {useEffect, useState} from 'react';
import {Box, IconButton} from "@mui/material";
import useResource from "../hooks/useResource";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import EnhancedTable from "../components/Table/EnhancedTable";
import useTable from "../hooks/useTable";
import {Delete, Edit, Visibility} from "@mui/icons-material";
import Filters from "../components/Filters/Filters";
import {filtersElementsTypes} from "../utils/constants";
import {networkIntegrationService} from "../api/services/networkIntegrationService";
import {categoryService} from "../api/services/categoryService";
import {externalSourceReportingService} from "../api/services/externalSourceReportingService";
import useUrlParams from "../hooks/useUrlParams";
import useData from "../hooks/useData";
import {campaignReportingService} from "../api/services/campaignReportingService";
import {offerService} from "../api/services/offerService";
import {mailingListService} from "../api/services/mailingListService";
import {verticalService} from "../api/services/verticalService";
import {brandService} from "../api/services/brandService";
import {partnerService} from "../api/services/partnerService";
import {espIntegrationService} from "../api/services/espIntegrationService";
import CampaignReportingDataAlertChip from "../components/CampaignReporting/CampaignReportingDataAlertChip";
import ExternalLinkButton from "../components/Campaign/ExternalLinkButton";
import ContentReportingPreviewModal from "../components/ContentReporting/ContentReportingPreviewModal";
import dayjs from "dayjs";
import {externalSourceService} from "../api/services/externalSourceService";
import {externalSourceTypeService} from "../api/services/externalSourceTypeService";
import {internalSourceReportingService} from "../api/services/internalSourceReportingService";
import {mailingListGroupService} from "../api/services/mailingListGroupService";

const InternalSourceReporting = () => {
    const urlParams = useUrlParams()

    const [modalOpen, setModalOpen] = useState(null)

    const handleModalClose = () => {
        setModalOpen(null)
    }

    const handleModalOpen = (content_id) => {
        setModalOpen(content_id)
    }

    const {
        items,
        totals,
        itemsPagination,
        itemsSort,
        itemsFilters,
        handleRefreshItems,
        fetchItemsApi,
    } = useData(
        internalSourceReportingService.getItems,
        {
            common: {
                type: filtersElementsTypes.TEXT,
            },
            send_datetime_start: {
                type: filtersElementsTypes.DATE,
                label: "Range Date Start",
                columns: 6,
                disableFuture: true,
                defaultValue: dayjs().startOf('month')
            },
            send_datetime_end: {
                type: filtersElementsTypes.DATE,
                label: "Range Date End",
                columns: 6,
                disableFuture: true
            },
            mailing_list_group_id: {
                label: "List Groups",
                apiCall: mailingListGroupService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            mailing_list_id: {
                label: "Lists",
                apiCall: mailingListService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            offer_id: {
                label: "Offers",
                apiCall: offerService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            partner_id: {
                label: "Partners",
                apiCall: partnerService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            network_integration_id: {
                label: "Network Integrations",
                apiCall: networkIntegrationService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            external_source_id: {
                label: "Sources",
                apiCall: externalSourceService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            external_source_type_id: {
                label: "Source Types",
                apiCall: externalSourceTypeService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
        },
        {},
        {
            sortBy: "revenue",
            sortOrder: "desc"
        },
        urlParams.queryParams
    )

    useEffect(() => {
        urlParams.updateUrlParams(
            itemsFilters,
            itemsPagination,
            itemsSort,
        )
    }, [
        itemsPagination.values,
        itemsFilters.values,
        itemsSort.values,
    ])

    const headCells = [
        {
            id: 'mailing_list_name',
            // numeric: false,
            disablePadding: false,
            label: 'List',
            width: "195px",
            highlight: true
        },
        {
            id: 'campaign_id',
            numeric: false,
            disablePadding: false,
            label: 'Tracking Id',
            width: "100px"
        },
        {
            id: 'external_id',
            numeric: false,
            disablePadding: false,
            label: 'ESP Id',
            width: "100px"
        },
        {
            id: 'offer_name',
            // numeric: false,
            disablePadding: false,
            label: 'Offer',
            width: "220px",
            highlight: true
        },
        {
            id: 'external_source_type_name',
            numeric: false,
            disablePadding: false,
            label: 'Source Type',
            width: "220px"
        },
        {
            id: 'external_source_tracking_id',
            numeric: false,
            disablePadding: false,
            label: 'Tracking Tag',
            width: "170px"
        },
        {
            id: 'network_integration_name',
            numeric: false,
            disablePadding: false,
            label: 'Network Integration',
            width: "200px"
        },
        {
            id: 'campaign_send_time',
            numeric: false,
            disablePadding: true,
            fullDatetime: true,
            label: 'Sent At',
            width: "120px"
        },

        {
            id: 'clicks',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Clicks',
            width: "100px",
            totals: totals.total_clicks
        },
        {
            id: 'epc',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'EPC',
            width: "100px",
            float: true,
            trailingSign: "$",
            totals: totals.total_epc
        },
        {
            id: 'conversions',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Conversions',
            width: "150px",
            totals: totals.total_conversions
        },
        {
            id: 'revenue',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Revenue',
            width: "100px",
            float: true,
            trailingSign: "$",
            totals: totals.total_revenue
        },
    ];

    const table = useTable(items, itemsPagination)

    return <>
        <Box>
            <CustomAppBar/>
            <Filters filters={itemsFilters}/>
            <EnhancedTable
                table={table}
                disableSelecting={true}
                headCells={headCells}
                data={{
                    items: items,
                    sort: itemsSort,
                    pagination: itemsPagination,
                    api: fetchItemsApi
                }}
                totals={totals}
                searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
            />
        </Box>
        <ContentReportingPreviewModal
            open={modalOpen}
            handleClose={handleModalClose}
        />
    </>
}

export default InternalSourceReporting