import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import AppLayout from "../components/AppLayout";
import Dashboard from "../pages/Dashboard";
import NetworkIntegration from "../pages/NetworkIntegrations";
import {routes} from "../utils/routes";
import Offers from "../pages/Offers";
import Contents from "../pages/Contents";
import {connect} from "react-redux";
import Categories from "../pages/Categories";
import EspIntegrations from "../pages/EspIntegrations";
import Brands from "../pages/Brands";
import Verticals from "../pages/Verticals";
import Tags from "../pages/Tags";
import Templates from "../pages/Templates";
import MailingLists from "../pages/MailingLists";
import Configs from "../pages/Configs";
import MailingListsGroups from "../pages/MailingListsGroups";
import ManualSchedule from "../pages/ManualSchedule";
import Campaigns from "../pages/Campaigns";
import ContentReporting from "../pages/ContentReporting";
import CampaignReporting from "../pages/CampaignReporting";
import OfferReporting from "../pages/OfferReporting";
import MailingListReporting from "../pages/MailingListReporting";
import AutomatedSchedulingConfigs from "../pages/AutomatedSchedulingConfigs";
import ExternalSources from "../pages/ExternalSources";
import ExternalSourceTypes from "../pages/ExternalSourceTypes";
import ExternalSourceReporting from "../pages/ExternalSourceReporting";
import UnknownSourceReporting from "../pages/UnknownSourceReporting";
import InternalSourceReporting from "../pages/InternalSourceReporting";
import PartnerReporting from "../pages/PartnerReporting";
import DynamicTags from "../pages/DynamicTags";
import NetworkIntegrationReporting from "../pages/NetworkIntegrationReporting";


const AppNavigation = ({isAuthenticated}) => {

    const navigate = useNavigate()

    useEffect(() => {
        if (!isAuthenticated) {
            navigate(routes.login)
        }
    }, [isAuthenticated, navigate])

    return (
        <Routes>
            <Route element={<AppLayout/>}>
                <Route index element={<Dashboard/>}/>
                <Route path={"content-reporting"} element={<ContentReporting/>}/>
                <Route path={"campaign-reporting"} element={<CampaignReporting/>}/>
                <Route path={"automated-scheduling-configs"} element={<AutomatedSchedulingConfigs/>}/>
                <Route path={"offer-reporting"} element={<OfferReporting/>}/>
                <Route path={"mailing-list-reporting"} element={<MailingListReporting/>}/>
                <Route path={"network-integrations"} element={<NetworkIntegration/>}/>
                <Route path={"offers"} element={<Offers/>}/>
                <Route path={"contents"} element={<Contents/>}/>
                <Route path={"categories"} element={<Categories/>}/>
                <Route path={"esp-integrations"} element={<EspIntegrations/>}/>
                <Route path={"brands"} element={<Brands/>}/>
                <Route path={"verticals"} element={<Verticals/>}/>
                <Route path={"tags"} element={<Tags/>}/>
                <Route path={"templates"} element={<Templates/>}/>
                <Route path={"external-sources"} element={<ExternalSources/>}/>
                <Route path={"external-source-types"} element={<ExternalSourceTypes/>}/>
                <Route path={"external-source-reporting"} element={<ExternalSourceReporting/>}/>
                <Route path={"unknown-source-reporting"} element={<UnknownSourceReporting/>}/>
                <Route path={"internal-source-reporting"} element={<InternalSourceReporting/>}/>
                <Route path={"network-integration-reporting"} element={<NetworkIntegrationReporting/>}/>
                <Route path={"partner-reporting"} element={<PartnerReporting/>}/>
                <Route path={"dynamic-tag"} element={<DynamicTags/>}/>

                <Route path={"lists"}>
                    <Route index element={<MailingLists/>}/>
                    <Route path={":mailingListId/configs"} element={<Configs/>}/>
                </Route>

                <Route path={"lists-groups"} element={<MailingListsGroups/>}/>

                <Route path={"manual-schedule"} element={<ManualSchedule/>}/>
                <Route path={"campaign"} element={<Campaigns/>}/>
                <Route
                    path="*"
                    element={<Navigate to={routes.homePage} replace/>}
                />
            </Route>
        </Routes>
    )
};


const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.auth.token,
    };
};

export default connect(mapStateToProps)(AppNavigation);