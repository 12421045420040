import React from 'react';
import {Grid} from "@mui/material";
import {tagService} from "../../../api/services/tagService";
import {filtersElementsTypes} from "../../../utils/constants";
import {dynamicTagItemService} from "../../../api/services/dynamicTagItemService";

import useTable from "../../../hooks/useTable";
import {useDispatch} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import useApi from "../../../hooks/useApi";
import Filters from "../../Filters/Filters";
import MultipleSelectButton from "../../MultipleSelectButton";
import {ArrowRight, Delete} from "@mui/icons-material";
import useData from "../../../hooks/useData";
import useSelectableData from "../../../hooks/useSelectableData";
import useDeleteData from "../../../hooks/useDeleteData";
import useDeleteMultipleData from "../../../hooks/useDeleteMultipleData";
import EnhancedTable from "../../Table/EnhancedTable";
import {mailingListService} from "../../../api/services/mailingListService";

const EditDynamicTagItem = ({dynamicTag}) => {
    const dispatch = useDispatch()

    const {
        items: dynamicTagItems,
        itemsPagination: dynamicTagItemsPagination,
        itemsSort: dynamicTagItemsSort,
        itemsFilters: dynamicTagItemsFilters,
        handleRefreshItems: handleRefreshDynamicTagItems,
        fetchItemsApi: fetchDynamicTagItemsApi,
    } = useData(
        (args) => dynamicTagItemService.getItems(
            {
                ...args,
                dynamic_tag_id: dynamicTag.id
            }),
        {
            name: {
                type: filtersElementsTypes.TEXT,
                label: "Name"
            },
        },
        {},
        {},
    )

    const {
        items: availableDynamicTagItems,
        itemsPagination: availableDynamicTagItemsPagination,
        itemsSort: availableDynamicTagItemsSort,
        itemsFilters: availableDynamicTagItemsFilters,
        handleRefreshItems: handleRefreshAvailableDynamicTagItems,
        fetchItemsApi: fetchAvailableDynamicTagItemsApi,
    } = useData(
        (args) => tagService.getTagsNotInDynamicTag({
            ...args,
            excluded_dynamic_tag_id: dynamicTag.id
        }),
        {
            tag_name: {
                type: filtersElementsTypes.TEXT,
                label: "Name"
            },
        },
        {},
        {},
    )

    const {
        selectedItems: selectedDynamicTagItems,
        handleSelectItem: handleSelectDynamicTagItems,
        setSelectedItems: setSelectedDynamicTagItems,
        handleClearSelectedItems: handleClearSelectedDynamicTagItems
    } = useSelectableData()

    const {
        selectedItems: selectedAvailableDynamicTagItems,
        handleSelectItem: handleSelectAvailableDynamicTagItems,
        setSelectedItems: setSelectedAvailableDynamicTagItems,
        handleClearSelectedItems: handleClearSelectedAvailableDynamicTagItems
    } = useSelectableData()

    const {
        deleteItem: deleteDynamicTagItem,
        deleteItemApi: deleteDynamicTagItemApi,
        handleDeleteItem: handleDeleteDynamicTagItem
    } = useDeleteData(
        dynamicTagItemService.deleteItem,
        () => {
            handleRefreshDynamicTagItems()
            handleRefreshAvailableDynamicTagItems()
        },
        handleSelectDynamicTagItems
    )

    const {
        deleteItems: deleteDynamicTagItems,
        handleOpenDeleteMultipleItemsModal: handleOpenDeleteMultipleDynamicTagItemsModal,
        handleCloseDeleteMultipleItemsModal: handleCloseDeleteMultipleDynamicTagItemsModal,
        handleDeleteMultipleItems: handleDeleteMultipleDynamicTagItems,
        deleteItemsApi: deleteDynamicTagItemsApi,
    } = useDeleteMultipleData(
        dynamicTagItemService.deleteItems,
        selectedDynamicTagItems,
        () => {
            handleRefreshDynamicTagItems()
            handleRefreshAvailableDynamicTagItems()
        },
        setSelectedDynamicTagItems
    )

    const dynamicTagItemsTable = useTable(
        dynamicTagItems,
        dynamicTagItemsPagination,
        selectedDynamicTagItems,
        handleSelectDynamicTagItems
    )

    const availableDynamicTagItemsTable = useTable(
        availableDynamicTagItems,
        availableDynamicTagItemsPagination,
        selectedAvailableDynamicTagItems,
        handleSelectAvailableDynamicTagItems
    )

    const addDynamicTagItemAPI = useApi(dynamicTagItemService.createItem)


    const handleAddAvailableDynamicTagItem = (item) => {
        console.debug({
            tag_id: item.id,
            dynamic_tag_id: dynamicTag.id
        })
        addDynamicTagItemAPI.makeRequest(
            {
                tag_id: item.id,
                dynamic_tag_id: dynamicTag.id
            },
            (response) => {
                dispatch(setSnack(response.message))
                handleRefreshDynamicTagItems()
                handleRefreshAvailableDynamicTagItems()
                setSelectedAvailableDynamicTagItems([])
            })
    }

    const addMultipleAvailableDynamicTagItemsApi = useApi(dynamicTagItemService.createItems)

    const handleAddMultipleMailingListsGroupItems = () => addMultipleAvailableDynamicTagItemsApi.makeRequest(
        {
            tag_ids: selectedAvailableDynamicTagItems,
            dynamicTag_id: dynamicTag.id
        },
        (response) => {
            dispatch(setSnack(response.message))
            handleRefreshDynamicTagItems()
            handleRefreshAvailableDynamicTagItems()
            setSelectedAvailableDynamicTagItems([])
        })

    const dynamicTagItemsTableHeadCells = [
        {
            id: 'tag.name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
            nested: true
        },
        {
            action: handleDeleteDynamicTagItem,
            icon: <Delete/>,
        }
    ];

    const availableDynamicTagItemsTableHeadCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
            nested: true
        },
        {
            action: handleAddAvailableDynamicTagItem,
            icon: <ArrowRight/>,
        }
    ];

    return (
        <>
            <Grid xs={12} item container spacing={2}>
                <Grid item xs={6} spacing={2} container>
                    <Grid item xs={12}>
                        <Filters filters={availableDynamicTagItemsFilters} sx={{maxWidth: "100%"}}/>
                    </Grid>

                    <Grid item xs={12}>
                        <MultipleSelectButton
                            alwaysVisible={true}
                            loading={addMultipleAvailableDynamicTagItemsApi.loading}
                            selectedItems={selectedAvailableDynamicTagItems}
                            onClearSelectedItems={handleClearSelectedAvailableDynamicTagItems}
                            onDeleteMultipleItems={handleAddMultipleMailingListsGroupItems}
                            icon={<ArrowRight/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <EnhancedTable
                            disableSelect={true}
                            table={availableDynamicTagItemsTable}
                            headCells={availableDynamicTagItemsTableHeadCells}

                            data={{
                                items: availableDynamicTagItems,
                                sort: availableDynamicTagItemsSort,
                                pagination: availableDynamicTagItemsPagination,
                                api: fetchAvailableDynamicTagItemsApi
                            }}
                            searchTerm={availableDynamicTagItemsFilters.values[availableDynamicTagItemsFilters.primaryFilter]}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6} container spacing={2}>
                    <Grid item xs={12}>
                        <Filters filters={dynamicTagItemsFilters} sx={{maxWidth: "100%"}}/>
                    </Grid>

                    <Grid item xs={12}>
                        <MultipleSelectButton
                            alwaysVisible={true}
                            loading={deleteDynamicTagItemsApi.loading}
                            selectedItems={selectedDynamicTagItems}
                            onClearSelectedItems={handleClearSelectedDynamicTagItems}
                            onDeleteMultipleItems={handleDeleteMultipleDynamicTagItems}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <EnhancedTable
                            disableSelect={true}
                            table={dynamicTagItemsTable}
                            headCells={dynamicTagItemsTableHeadCells}

                            data={{
                                items: dynamicTagItems,
                                sort: dynamicTagItemsSort,
                                pagination: dynamicTagItemsPagination,
                                api: fetchDynamicTagItemsApi
                            }}
                            searchTerm={dynamicTagItemsFilters.values[dynamicTagItemsFilters.primaryFilter]}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default EditDynamicTagItem;