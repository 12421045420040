import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import EnhancedTable from "../components/Table/EnhancedTable";
import useTable from "../hooks/useTable";
import Filters from "../components/Filters/Filters";
import {filtersElementsTypes} from "../utils/constants";
import {mailingListReportingService} from "../api/services/mailingListReportingService";
import {espIntegrationService} from "../api/services/espIntegrationService";
import {brandService} from "../api/services/brandService";
import {partnerService} from "../api/services/partnerService";
import useUrlParams from "../hooks/useUrlParams";
import useData from "../hooks/useData";
import {mailingListService} from "../api/services/mailingListService";
import ContentReportingPreviewModal from "../components/ContentReporting/ContentReportingPreviewModal";
import dayjs from "dayjs";
import {mailingListGroupService} from "../api/services/mailingListGroupService";

const MailingListReporting = () => {
    const urlParams = useUrlParams()

    const {
        items,
        totals,
        itemsPagination,
        itemsSort,
        itemsFilters,
        fetchItemsApi,
    } = useData(
        mailingListReportingService.getItems,
        {
            common: {
                type: filtersElementsTypes.TEXT,
            },
            send_datetime_start: {
                type: filtersElementsTypes.DATE,
                label: "Send Date Start",
                columns: 6,
                disableFuture: true,
                defaultValue: dayjs().startOf('month')
            },
            send_datetime_end: {
                type: filtersElementsTypes.DATE,
                label: "Send Date End",
                columns: 6,
                disableFuture: true
            },
            mailing_list_group_id: {
                label: "List Groups",
                apiCall: mailingListGroupService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            mailing_list_id: {
                label: "List",
                apiCall: mailingListService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            brand_id: {
                label: "From Email (Brands)",
                apiCall: brandService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
                searchField: "from_email",
                setOptionLabel: (item) => item.from_email,
            },
            partner_id: {
                label: "Partners",
                apiCall: partnerService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            esp_integration_id: {
                label: "ESP Integrations",
                apiCall: espIntegrationService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            min_sends: {
                type: filtersElementsTypes.NUMBER,
                label: "Min Sends",
                columns: 6
            },
            min_clicks: {
                type: filtersElementsTypes.NUMBER,
                label: "Min Clicks",
                columns: 6
            },
            send_time_start: {
                type: filtersElementsTypes.TIME,
                label: "Send Time Start",
                columns: 6
            },
            send_time_end: {
                type: filtersElementsTypes.TIME,
                label: "Send Time End",
                columns: 6
            }
        },
        {},
        {
            sortBy: "esp_integration_name",
            sortOrder: "asc"
        },
        urlParams.queryParams
    )

    useEffect(() => {
        urlParams.updateUrlParams(
            itemsFilters,
            itemsPagination,
            itemsSort,
        )
    }, [
        itemsPagination.values,
        itemsFilters.values,
        itemsSort.values,
    ])

    const headCells = [
        {
            id: 'esp_integration_name',
            // numeric: false,
            disablePadding: false,
            label: 'ESP',
            width: "80px",
            highlight: true
        },
        {
            id: 'partner_name',
            numeric: false,
            disablePadding: false,
            label: 'Partner',
            width: "80px"
        },
        {
            id: 'mailing_list_name',
            numeric: false,
            disablePadding: false,
            label: 'List',
            width: "300px"
        },
        {
            id: 'brand_name',
            numeric: false,
            disablePadding: false,
            label: 'Brand',
            width: "150px"
        },
        {
            id: 'from_email',
            numeric: false,
            disablePadding: false,
            label: 'From Email',
            width: "200px"
        },
        {
            id: 'sends',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Sends',
            width: "150px",
            totals: totals.total_sends
        },
        {
            id: 'clicks',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Clicks',
            width: "150px",
            totals: totals.total_clicks
        },
        {
            id: 'revenue',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Revenue',
            width: "150px",
            float: true,
            trailingSign: "$",
            totals: totals.total_revenue
        },
        {
            id: 'epc',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'EPC',
            width: "100px",
            float: true,
            trailingSign: "$",
            totals: totals.total_epc
        },
        {
            id: 'ppm',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'PPM',
            width: "100px",
            float: true,
            trailingSign: "$",
            totals: totals.total_ppm
        },
    ];

    const table = useTable(items, itemsPagination)

    return <>
        <Box>
            <CustomAppBar/>
            <Filters filters={itemsFilters}/>
            <EnhancedTable
                table={table}
                disableSelecting={true}
                headCells={headCells}
                data={{
                    items: items,
                    sort: itemsSort,
                    pagination: itemsPagination,
                    api: fetchItemsApi
                }}
                totals={totals}
                searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
            />
        </Box>
    </>
}

export default MailingListReporting