import React, {useEffect, useState} from 'react';
import {Box, useTheme} from "@mui/material";
import useResource from "../hooks/useResource";
import useModal from "../hooks/useModal";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import AddNetworkIntegrationModal
    from "../components/NetworkIntegration/NetworkIntegrationModal/AddNetworkIntegrationModal";
import EnhancedTable from "../components/Table/EnhancedTable";
import useTable from "../hooks/useTable";
import {Delete, Edit} from "@mui/icons-material";
import ConfirmationModal from "../components/ConfirmationModal";
import {offerService} from "../api/services/offerService";
import {networkService} from "../api/services/networkService";
import Filters from "../components/Filters/Filters";
import {filtersElementsTypes} from "../utils/constants";
import EditNetworkIntegrationModal
    from "../components/NetworkIntegration/NetworkIntegrationModal/EditNetworkIntegrationModal";
import AddOffer from "../components/Offer/AddOffer";
import AddOfferModal from "../components/Offer/OfferModal/AddOfferModal";
import EditOfferModal from "../components/Offer/OfferModal/EditOfferModal";
import {networkIntegrationService} from "../api/services/networkIntegrationService";
import OfferStatus from "../components/Offer/OfferStatus";
import CustomSwitchWithConfirmation from "../components/CustomSwitchWithConfirmation";
import useApi from "../hooks/useApi";
import useConfirmationModal from "../hooks/useConfirmationModal";
import useDeleteMultipleItems from "../hooks/useDeleteMultipleItems";
import useUrlParams from "../hooks/useUrlParams";
import useData from "../hooks/useData";
import useSelectableData from "../hooks/useSelectableData";
import useDeleteData from "../hooks/useDeleteData";
import useDeleteMultipleData from "../hooks/useDeleteMultipleData";
import useUpdateData from "../hooks/useUpdateData";
import useAddData from "../hooks/useAddData";
import {externalSourceService} from "../api/services/externalSourceService";
import CopyToClipboardButton from "../components/CopyToClipboardButton";
import AddExternalSourceModal from "../components/ExternalSource/ExternalSourceModal/AddExternalSourceModal";
import EditExternalSourceModal from "../components/ExternalSource/ExternalSourceModal/EditExternalSourceModal";
import {externalSourceTypeService} from "../api/services/externalSourceTypeService";

const ExternalSources = () => {
    const urlParams = useUrlParams()
    const theme = useTheme()

    const {
        items,
        itemsPagination,
        itemsSort,
        itemsFilters,
        handleRefreshItems,
        fetchItemsApi,
    } = useData(
        externalSourceService.getItems,
        {
            common: {
                type: filtersElementsTypes.TEXT,
            },
            name: {
                type: filtersElementsTypes.TEXT,
                label: "Name",
            },
            external_source_type_id: {
                label: "Source Type",
                apiCall: externalSourceTypeService.getItems,
                searchField: "name",
                setOptionLabel: (item) => item.name,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            offer_id: {
                label: "Offer",
                apiCall: offerService.getItems,
                searchField: "name",
                setOptionLabel: (item) => item.name,
                type: filtersElementsTypes.MULTISELECT_API,
            },
        },
        {},
        {},
        urlParams.queryParams
    );

    const {selectedItems, handleSelectItem, setSelectedItems} = useSelectableData()
    const {
        deleteItem,
        handleOpenDeleteItemModal,
        handleCloseDeleteItemModal,
        handleConfirmDeleteItem,
        deleteItemApi,
    } = useDeleteData(
        externalSourceService.deleteItem,
        handleRefreshItems,
        handleSelectItem
    )

    const {
        deleteItems,
        handleOpenDeleteMultipleItemsModal,
        handleCloseDeleteMultipleItemsModal,
        handleDeleteMultipleItems,
        deleteItemsApi,
    } = useDeleteMultipleData(
        externalSourceService.deleteItems,
        selectedItems,
        handleRefreshItems,
        setSelectedItems
    )

    const {
        updateItemId,
        updateItemData,
        handleOpenEditItemModal,
        handleCloseEditItemModal,
    } = useUpdateData(
        externalSourceService.getItems,
        urlParams.queryParams
    )

    const {
        createItemModalOpen,
        handleOpenCreateItemModal,
        handleCloseCreateItemModal,
    } = useAddData()



    useEffect(() => {
        urlParams.updateUrlParams(
            itemsFilters,
            itemsPagination,
            itemsSort,
            {edit: updateItemId?.value},
        )
    }, [
        itemsPagination.values,
        itemsFilters.values,
        itemsSort.values,
        updateItemId
    ])


    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'external_source_type.name',
            numeric: false,
            disablePadding: false,
            label: 'Source Type',
            nested: true,
            width: "200px"
        },
        {
            id: 'offer.name',
            numeric: false,
            disablePadding: false,
            label: 'Offer',
            nested: true,
            width: "10%"
        },
        {
            id: 'tracking_id',
            numeric: false,
            disablePadding: false,
            label: 'Tracking Tag',
            nested: true,
            width: "10%",
            disableSort: true
        },
        {
            id: 'tracking_link',
            numeric: false,
            disablePadding: false,
            label: 'Link',
            width: "25%",
            disableSort: true
        },
        {
            element: (item) => <CopyToClipboardButton data={item.tracking_link}/>,
            width: theme.customTokens.defaultTableIconWidth
        },
        {
            id: 'updated_at',
            align: "right",
            disablePadding: false,
            label: 'Updated At',
            datetime: true,
            width: "130px",

        },
        {
            id: 'created_at',
            align: "right",
            disablePadding: false,
            label: 'Created At',
            datetime: true,
            width: "130px"
        },
        {
            action: handleOpenEditItemModal,
            icon: <Edit/>,
        },
        {
            action: handleOpenDeleteItemModal,
            icon: <Delete/>,
        }
    ];

    const table = useTable(items, itemsPagination, selectedItems, handleSelectItem)

    console.debug(selectedItems)
    return <>
        <Box>
            <CustomAppBar handleModalOpen={handleOpenCreateItemModal} addButtonText={"Create External Source"}
                          selectedItems={selectedItems}
                          onDeleteMultipleItems={handleOpenDeleteMultipleItemsModal}/>
            <Filters filters={itemsFilters}/>
            <EnhancedTable
                table={table}
                headCells={headCells}
                data={{
                    items: items,
                    sort: itemsSort,
                    pagination: itemsPagination,
                    api: fetchItemsApi
                }}
                searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
            />
        </Box>
        {
            updateItemData && (<EditExternalSourceModal
                open={true}
                item={updateItemData}
                handleClose={handleCloseEditItemModal}
                handleRefreshItems={handleRefreshItems}
            />)
        }
        {
            createItemModalOpen &&
            <AddExternalSourceModal
                handleClose={handleCloseCreateItemModal}
                handleRefreshItems={handleRefreshItems}
                open={true}
            />
        }
        {deleteItem &&
            <ConfirmationModal
                title={"Delete Source"}
                description={
                    <>
                        Are you sure you want to delete the <strong>{deleteItem.name}</strong> source?
                    </>
                }
                handleClose={handleCloseDeleteItemModal}
                handleSubmit={handleConfirmDeleteItem}
                loading={deleteItemApi.loading}
                open={true}
            />
        }
        {
            (deleteItems && !!Object.keys(selectedItems).length) && <ConfirmationModal
                title={"Delete External Sources"}
                description={
                    <>
                        Are you sure you want to delete <strong>{Object.keys(selectedItems).length}</strong> source(s)?
                    </>
                }
                handleClose={handleCloseDeleteMultipleItemsModal}
                handleSubmit={handleDeleteMultipleItems}
                loading={deleteItemsApi.loading}
                open={true}
            />

        }
    </>
};


export default ExternalSources