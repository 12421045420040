import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from "qs";


const getItems = (
    args
) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            page: args.page,
            name: args.name,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            common: args.common
        }


        if (args.external_source_type_id) {
            data.external_source_type_id = args.external_source_type_id.map(option => option.value)
        }

        if (args.offer_id) {
            data.offer_id = args.offer_id.map(option => option.value)
        }

        apiClient.get(endpoints.externalSource, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

const createItem = (name, external_source_type_id, offer_id) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            name,
            external_source_type_id,
            offer_id
        }

        apiClient.post(endpoints.externalSource, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}


const updateItem = (
    external_source_id,
    name,
    external_source_type_id,
) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            name,
            external_source_type_id,
        }
        console.debug(data)
        apiClient.patch(endpoints.externalSource + "/" + external_source_id, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteItem = ({itemId}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.delete(endpoints.externalSource + "/" + itemId)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteItems = (items) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            items_to_delete: Object.keys(items),
        }

        apiClient.post(endpoints.externalSource + "/delete-multiple", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

export const externalSourceService = {
    getItems,
    createItem,
    updateItem,
    deleteItem,
    deleteItems
}