import React, {useEffect, useState} from 'react';
import {useForm, useWatch} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";

import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import {externalSourceService} from "../../../api/services/externalSourceService";
import ControlledSelectField from "../../ControlledSelectField";
import ControlledAutocompleteSelectField from "../../ControlledAutocompleteSelectField";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import CustomModal from "../../CustomModal/CustomModal";
import CustomModalBody from "../../CustomModal/CustomModalBody";
import ControlledAPIAutocompleteSelectField from "../../ControlledAPIAutocompleteSelectField";
import {networkIntegrationService} from "../../../api/services/networkIntegrationService";
import {categoryService} from "../../../api/services/categoryService";
import CustomAutocompleteSelectField from "../../CustomAutocompleteSelectField";
import CustomTextField from "../../CustomTextField";
import {externalSourceTypeService} from "../../../api/services/externalSourceTypeService";


const option = yup.object({
    key: yup.string().required('Key is required'),
    value: yup.number().typeError('Value must be a number').required('Value is required'),
});

const schema = yup.object().shape({
    external_source_type: option.required('Required field'),
    name: yup.string().required('Required field'),
});

const EditExternalSourceModal = (
    {
        setSnack,
        open,
        handleClose,
        handleRefreshItems,
        item,
    }
) => {
    const [loading, setLoading] = useState(false)
    const {
        formState,
        control,
        watch,
        handleSubmit
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            external_source_type: item.external_source_type ? {
                key: item.external_source_type.name,
                value: item.external_source_type.id,
            } : null,
            name: item.name,
        }
    })

    const onSuccess = (data) => {
        setLoading(true)

        externalSourceService.updateItem(
            item.id,
            data.name,
            data.external_source_type.value,
        )
            .then((response) => {
                setSnack(response.message, "success")
                setLoading(false)
                handleClose()
                handleRefreshItems()
            })
            .catch((error) => {
                setSnack("Invalid Parameters", "error")
                setLoading(false)
            })

    }

    const onError = (error) => {
        // Handle Errors
        console.debug(error)
    }
    console.log(item)
    return (
        <CustomModal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            onSubmit={handleSubmit(onSuccess, onError)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Edit External Source"}/>
            <CustomModalBody>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ControlledTextField
                            control={control}
                            name={"name"}
                            label={"Name"}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ControlledAPIAutocompleteSelectField
                            control={control}
                            name={"external_source_type"}
                            label={"Source Type"}
                            apiCall={externalSourceTypeService.getItems}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomTextField
                            value={item.offer.name}
                            label={"Offer"}
                            disabled={true}
                        />
                    </Grid>
                </Grid>

            </CustomModalBody>

            <CustomModalFooter>
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Save External Source"}
                        loading={loading}
                        disabled={!formState.isDirty}
                    />
                </Grid>

            </CustomModalFooter>

        </CustomModal>

    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(EditExternalSourceModal);