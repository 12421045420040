import React, {useState} from 'react';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {setSnack} from "../../../store/actions/snack";
import {connect} from "react-redux";
import {mailingListGroupService} from "../../../api/services/mailingListGroupService";
import {Button, Grid} from "@mui/material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import CustomModal from "../../CustomModal/CustomModal";
import CustomModalBody from "../../CustomModal/CustomModalBody";
import EditDynamicTagItem from "./EditDynamicTagItem";

const schema = yup.object().shape({
    name: yup.string().required('Name is a required field'),
});

const EditDynamicTagModal = (
    {
        open,
        setSnack,
        handleClose,
        handleRefreshItems,
        item,
    }
) => {
    const [loading, setLoading] = useState(false);

    const {
        control,
        handleSubmit,
        reset,
        formState
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: item.name,
            symbol: item.symbol
        }
    });

    const onSuccess = async (data) => {
        setLoading(true);

        mailingListGroupService.updateItem(item.id, data.name)
            .then((response) => {
                setSnack(response.message, "success")
                handleClose()
                handleRefreshItems()
            })
            .catch((error) => {
                setSnack("Invalid Parameters", "error")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onError = (error) => {
        console.debug(error);
    }
    return (
        <CustomModal
            open={open}
            // onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            // component={"form"}
            maxWidth={"lg"}
        >
            <CustomModalHeader handleClose={handleClose} title={"Edit Dynamic Tag"}/>
            <CustomModalBody>
                <Grid container columnSpacing={2}>
                    <Grid item xs={6}>
                        <ControlledTextField control={control} name={"name"} label={"Name"}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ControlledTextField control={control} name={"symbol"} label={"Symbol"}/>
                    </Grid>
                    <EditDynamicTagItem
                        dynamicTag={item}
                    />
                </Grid>
            </CustomModalBody>
            <CustomModalFooter>
                {/*<Grid container spacing={2} sx={{mt: 3}}>*/}
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Save Dynamic Tag"}
                        loading={loading}
                        type={"button"}
                        onSubmit={handleSubmit(onSuccess, onError)}
                        disabled={!formState.isDirty}
                    />
                </Grid>

                {/*</Grid>*/}
            </CustomModalFooter>


            {/*</Container>*/}
        </CustomModal>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(EditDynamicTagModal);