import React from 'react';
import {Autocomplete, Checkbox} from "@mui/material";
import CustomTextField from "./CustomTextField";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const CustomAutocompleteSelectField = (
    {
        value,
        label,
        onChange,
        options,
        error = null,
        multiple = false,
        variant = "standard",
        showHelperText = true,
        filterSelectedOptions= true,
        disableCloseOnSelect= false,
        isOptionEqualToValue = (option, value) => option.value === value.value,
        disabled=false
    }
) => {



    return (<Autocomplete
            multiple={multiple}
            id="tags-outlined"
            options={options}
            getOptionLabel={(option) => option.key}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            value={value}
            filterSelectedOptions={filterSelectedOptions}
            disableCloseOnSelect={disableCloseOnSelect}

            renderInput={(params) => (
                <CustomTextField
                    {...params}
                    label={label}
                    error={!!error}
                    helperText={showHelperText ? (error ? error.message : " ") : null}
                    variant={variant}
                    disabled={disabled}
                />
            )}
        />
    )
};

export default CustomAutocompleteSelectField;