import React from 'react';
import {IconButton} from '@mui/material';
import {useDispatch} from "react-redux";
import {CopyAll} from "@mui/icons-material";
import {setSnack} from "../store/actions/snack";

const CopyToClipboardButton = ({data}) => {
    const dispatch = useDispatch()

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(data).then(() => {
            dispatch(setSnack("Affiliate link copied to clipboard", "success"))
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };

    return (
        <IconButton onClick={handleCopyToClipboard}>
            <CopyAll/>
        </IconButton>
    )
};

export default CopyToClipboardButton;
