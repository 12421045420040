import React, {useEffect, useState} from 'react';
import {Box, IconButton} from "@mui/material";
import useResource from "../hooks/useResource";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import EnhancedTable from "../components/Table/EnhancedTable";
import useTable from "../hooks/useTable";
import {Delete, Edit, Visibility} from "@mui/icons-material";
import Filters from "../components/Filters/Filters";
import {filtersElementsTypes} from "../utils/constants";
import {networkIntegrationService} from "../api/services/networkIntegrationService";
import {categoryService} from "../api/services/categoryService";
import {externalSourceReportingService} from "../api/services/externalSourceReportingService";
import useUrlParams from "../hooks/useUrlParams";
import useData from "../hooks/useData";
import {campaignReportingService} from "../api/services/campaignReportingService";
import {offerService} from "../api/services/offerService";
import {mailingListService} from "../api/services/mailingListService";
import {verticalService} from "../api/services/verticalService";
import {brandService} from "../api/services/brandService";
import {partnerService} from "../api/services/partnerService";
import {espIntegrationService} from "../api/services/espIntegrationService";
import CampaignReportingDataAlertChip from "../components/CampaignReporting/CampaignReportingDataAlertChip";
import ExternalLinkButton from "../components/Campaign/ExternalLinkButton";
import ContentReportingPreviewModal from "../components/ContentReporting/ContentReportingPreviewModal";
import dayjs from "dayjs";
import {externalSourceService} from "../api/services/externalSourceService";
import {externalSourceTypeService} from "../api/services/externalSourceTypeService";
import {partnerReportingService} from "../api/services/partnerReportingService";

const PartnerReporting = () => {
    const urlParams = useUrlParams()

    const [modalOpen, setModalOpen] = useState(null)

    const handleModalClose = () => {
        setModalOpen(null)
    }

    const handleModalOpen = (content_id) => {
        setModalOpen(content_id)
    }

    const {
        items,
        totals,
        itemsPagination,
        itemsSort,
        itemsFilters,
        handleRefreshItems,
        fetchItemsApi,
    } = useData(
        partnerReportingService.getItems,
        {
            common: {
                type: filtersElementsTypes.TEXT,
            },
            send_datetime_start: {
                type: filtersElementsTypes.DATE,
                label: "Range Date Start",
                columns: 6,
                disableFuture: true,
                defaultValue: dayjs().startOf('month')
            },
            send_datetime_end: {
                type: filtersElementsTypes.DATE,
                label: "Range Date End",
                columns: 6,
                disableFuture: true
            },
            partner_id: {
                label: "Partners",
                apiCall: partnerService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
        },
        {},
        {
            sortBy: "partner_name",
            sortOrder: "desc"
        },
        urlParams.queryParams
    )

    useEffect(() => {
        urlParams.updateUrlParams(
            itemsFilters,
            itemsPagination,
            itemsSort,
        )
    }, [
        itemsPagination.values,
        itemsFilters.values,
        itemsSort.values,
    ])

    const headCells = [
        {
            id: 'partner_name',
            // numeric: false,
            disablePadding: false,
            label: 'Partner',
            width: "195px",
            highlight: true
        },
        {
            id: 'clicks',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Clicks',
            width: "100px",
            totals: totals.total_clicks
        },
        {
            id: 'conversions',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Conversions',
            width: "100px",
            totals: totals.total_conversions
        },
        {
            id: 'sends',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Sends',
            width: "100px",
            totals: totals.total_sends
        },
        {
            id: 'revenue',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Revenue',
            width: "100px",
            float: true,
            trailingSign: "$",
            totals: totals.total_revenue
        },
    ];

    const table = useTable(items, itemsPagination)

    return <>
        <Box>
            <CustomAppBar/>
            <Filters filters={itemsFilters}/>
            <EnhancedTable
                table={table}
                disableSelecting={true}
                headCells={headCells}
                data={{
                    items: items,
                    sort: itemsSort,
                    pagination: itemsPagination,
                    api: fetchItemsApi
                }}
                totals={totals}
                searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
            />
        </Box>
        <ContentReportingPreviewModal
            open={modalOpen}
            handleClose={handleModalClose}
        />
    </>
}

export default PartnerReporting