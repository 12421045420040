import {useState} from "react";

const useModal = () => {
    const [modalOpen, setModalOpen] = useState(false)

    const handleModalClose = () => {
        setModalOpen(false)
    }

    const handleModalOpen = (value = true) => {
        setModalOpen(value)
    }

    return {modalOpen, handleModalClose, handleModalOpen}
}

export default useModal;