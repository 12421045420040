import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from "qs";


const getItems = (args) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()
        const data = {
            page: args.page,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            common: args.common,
        }

       if (args.excude_internal !== undefined && args.excude_internal !== null){
            data.excude_internal = args.excude_internal.value
        }

        if (args.send_datetime_start !== "")
            data.send_datetime_start = args.send_datetime_start.format('YYYY-MM-DDTHH:mm:ss.SSS')
        if (args.send_datetime_end !== "")
            data.send_datetime_end = args.send_datetime_end.format('YYYY-MM-DDTHH:mm:ss.SSS')


        if (args.partner) {
            // const ids = args.partner.map(network => network.value)
            data.partner = args.partner.map(item => item.value)
        }

        console.debug(data)

        apiClient.get(endpoints.partnerReporting, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }})
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

export const partnerReportingService = {
    getItems,
}