import React, {useCallback, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography, useTheme} from "@mui/material";

import CustomModal from "../CustomModal/CustomModal";
import CustomModalHeader from "../CustomModal/CustomModalHeader";
import CustomModalBody from "../CustomModal/CustomModalBody";
import CustomModalFooter from "../CustomModal/CustomModalFooter";
import {setSnack} from "../../store/actions/snack";
import CodeArea from "../Content/CodeArea";
import {generateSrcDocWithStyles} from "../../utils/designToken";
import {campaignService} from "../../api/services/campaignService";


const CampaignPreviewModal = ({setSnack, open, handleClose}) => {
    const theme = useTheme();
    const [code, setCode] = useState(null)
    const [loadingCode, setLoadingCode] = useState(false)

    useEffect(() => {
        if (!!open) {
            fetchCampaignCode()
        }
    }, [open]);

    const onClose = () => {
        setCode(null)
        handleClose()
    }

    const getScrollbarStyles = useCallback(() => {
        const scrollbarStyles = theme.components?.MuiCssBaseline?.styleOverrides?.body || {};
        let css = '';

        // Convert JS object to CSS string
        Object.keys(scrollbarStyles).forEach(key => {
            const style = scrollbarStyles[key];
            css += `${key} {`;
            Object.keys(style).forEach(prop => {
                css += `${prop}: ${style[prop]};`;
            });
            css += `}`;
        });

        return css;
    }, [theme]);



    const fetchCampaignCode = () => {
        campaignService.previewEmail(open)
            .then((response) => {
                setLoadingCode(false);
                setCode(generateSrcDocWithStyles(response.data.html_part))
            })
            .catch((errorResponse) => {
                setLoadingCode(false);
                setSnack(errorResponse.message, "error");
            });
    }

    return (
        <CustomModal
            open={!!open}
            // onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            maxWidth={"md"}
        >
            <CustomModalHeader handleClose={onClose} title={"Campaign Preview"}/>
            <CustomModalBody>
                <Grid item xs={12}>
                    <CodeArea loading={loadingCode} height={700}>

                        <iframe
                            srcDoc={code}
                            title="Preview"
                            style={{
                                border: 'none',
                                width: '100%',
                                height: '98%',

                            }}
                        />

                    </CodeArea>

                </Grid>
            </CustomModalBody>
            <CustomModalFooter>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        onClick={() => onClose()}
                        fullWidth
                        variant={"contained"}
                    >
                        Close
                    </Button>
                </Grid>
            </CustomModalFooter>
        </CustomModal>

    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(CampaignPreviewModal);