import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from "qs";


const getItems = (args
) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()
        // console.debug(args.is_active)
        const data = {
            page: args.page,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            common: args.common,
        }

        console.debug(args)
        if (args.mailing_list_id) {
            // const ids = args.mailing_list_id.map(network => network.value)
            data.mailing_list_id = args.mailing_list_id.map(network => network.value)
        }
        if (args.mailing_list_group_id) {
            // const ids = args.mailing_list_group_id.map(network => network.value)
            data.mailing_list_group_id = args.mailing_list_group_id.map(network => network.value)
        }
        if (args.vertical_id) {
            // const ids = args.vertical_id.map(network => network.value)
            data.vertical_id = args.vertical_id.map(network => network.value)
        }
        if (args.category_id) {
            // const ids = args.category_id.map(network => network.value)
            data.category_id = args.category_id.map(network => network.value)
        }
        if (args.brand_id) {
            // const ids = args.brand_id.map(network => network.value)
            data.brand_id = args.brand_id.map(network => network.value)
        }
        if (args.partner_id) {
            // const ids = args.partner_id.map(network => network.value)
            data.partner_id = args.partner_id.map(network => network.value)
        }
        if (args.network_integration_id) {
            // const ids = args.network_integration_id.map(network => network.value)
            data.network_integration_id = args.network_integration_id.map(network => network.value)
        }
        if (args.esp_integration_id) {
            // const ids = args.esp_integration_id.map(network => network.value)
            data.esp_integration_id = args.esp_integration_id.map(network => network.value)
        }
        if (args.offer_id) {
            // const ids = args.offer_id.map(network => network.value)
            data.offer_id = args.offer_id.map(network => network.value)
        }
        if (args.status)
            data.status = args.status.value
        if (args.creation_method && args.creation_method.value)
            data.creation_method = args.creation_method.value
        if (args.send_time_start && args.send_time_start !== "")
            data.send_time_start = args.send_time_start.format('YYYY-MM-DDTHH:mm:ss.SSS')
        if (args.send_time_end && args.send_time_end !== "")
            data.send_time_end = args.send_time_end.format('YYYY-MM-DDTHH:mm:ss.SSS')
        if (args.send_datetime_start && args.send_datetime_start !== "")
            data.send_datetime_start = args.send_datetime_start.format('YYYY-MM-DDTHH:mm:ss.SSS')
        if (args.send_datetime_end && args.send_datetime_end !== "")
            data.send_datetime_end = args.send_datetime_end.format('YYYY-MM-DDTHH:mm:ss.SSS')


        apiClient.get(endpoints.campaign, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

const createItem = (
    campaigns
) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            campaigns,
        }

        apiClient.post(endpoints.scheduleMultiple, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteItem = (content_id) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.delete(endpoints.campaign + "/" + content_id)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}


const retryItem = (
    campaign_id
) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            campaign_id,
        }

        apiClient.post(endpoints.campaign + "/retry", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const retryItems = (
    items
) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            campaign_ids: Object.keys(items),
        }

        apiClient.post(endpoints.campaign + "/multiple-retry", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const unscheduleItem = (
    campaign_id
) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            campaign_id,
        }

        apiClient.post(endpoints.campaign + "/unschedule", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const unscheduleItems = (
    items
) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            campaign_ids: Object.keys(items),
        }

        apiClient.post(endpoints.campaign + "/multiple-unschedule", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const previewEmail = (campaign_id) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.get(endpoints.campaign + "/preview/" + campaign_id)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}


export const campaignService = {
    getItems,
    createItem,
    deleteItem,
    retryItem,
    unscheduleItem,
    retryItems,
    unscheduleItems,
    previewEmail
}