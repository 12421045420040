import React, {useCallback, useEffect, useState} from 'react';
import {Box} from "@mui/material";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import EnhancedTable from "../components/Table/EnhancedTable";
import useTable from "../hooks/useTable";
import {Delete, Edit} from "@mui/icons-material";
import Filters from "../components/Filters/Filters";
import {filtersElementsTypes} from "../utils/constants";
import useUrlParams from "../hooks/useUrlParams";
import ConfirmationModal from "../components/ConfirmationModal";
import useData from "../hooks/useData";
import useSelectableData from "../hooks/useSelectableData";
import useDeleteData from "../hooks/useDeleteData";
import useDeleteMultipleData from "../hooks/useDeleteMultipleData";
import useUpdateData from "../hooks/useUpdateData";
import useAddData from "../hooks/useAddData";
import {externalSourceTypeService} from "../api/services/externalSourceTypeService";
import EditExternalSourceTypeModal
    from "../components/ExternalSourceType/ExternalSourceTypeModal/EditExternalSourceTypeModal";
import AddExternalSourceTypeModal
    from "../components/ExternalSourceType/ExternalSourceTypeModal/AddExternalSourceTypeModal";

const ExternalSourceTypes = () => {
    const urlParams = useUrlParams()

    const {
        items,
        itemsPagination,
        itemsSort,
        itemsFilters,
        handleRefreshItems,
        fetchItemsApi,
    } = useData(
        externalSourceTypeService.getItems,
        {
            name: {
                type: filtersElementsTypes.TEXT,
            },
        },
        {},
        {},
        urlParams.queryParams
    );

    const {selectedItems, handleSelectItem, setSelectedItems} = useSelectableData()
    const {
        deleteItem,
        handleOpenDeleteItemModal,
        handleCloseDeleteItemModal,
        handleConfirmDeleteItem,
        deleteItemApi,
    } = useDeleteData(
        externalSourceTypeService.deleteItem,
        handleRefreshItems,
        handleSelectItem
    )

    const {
        deleteItems,
        handleOpenDeleteMultipleItemsModal,
        handleCloseDeleteMultipleItemsModal,
        handleDeleteMultipleItems,
        deleteItemsApi,
    } = useDeleteMultipleData(
        externalSourceTypeService.deleteItems,
        selectedItems,
        handleRefreshItems,
        setSelectedItems
    )

    const {
        updateItemId,
        updateItemData,
        handleOpenEditItemModal,
        handleCloseEditItemModal,
    } = useUpdateData(
        externalSourceTypeService.getItems,
        urlParams.queryParams,
        "category_id"
    )

    const {
        createItemModalOpen,
        handleOpenCreateItemModal,
        handleCloseCreateItemModal,
    } = useAddData()

    useEffect(() => {
        urlParams.updateUrlParams(
            itemsFilters,
            itemsPagination,
            itemsSort,
            {edit: updateItemId?.value}
        )
    }, [
        itemsPagination.values,
        itemsFilters.values,
        itemsSort.values,
        updateItemId
    ])

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'updated_at',
            align: "right",
            disablePadding: false,
            label: 'Updated At',
            datetime: true,
            width: "130px"
        },
        {
            id: 'created_at',
            align: "right",
            disablePadding: false,
            label: 'Created At',
            datetime: true,
            width: "130px"
        },
        {
            action: handleOpenEditItemModal,
            icon: <Edit/>,
        },
        {
            action: handleOpenDeleteItemModal,
            icon: <Delete/>,
        }
    ];

    const table = useTable(items, itemsPagination, selectedItems, handleSelectItem)
    console.debug(updateItemId)
    return <>
        <Box>
            <CustomAppBar handleModalOpen={handleOpenCreateItemModal} addButtonText={"Create External Source Type"}
                          selectedItems={selectedItems}
                          onDeleteMultipleItems={handleOpenDeleteMultipleItemsModal}/>
            <Filters filters={itemsFilters}/>
            <EnhancedTable
                table={table}
                headCells={headCells}
                data={{
                    items: items,
                    sort: itemsSort,
                    pagination: itemsPagination,
                    api: fetchItemsApi
                }}
                searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
            />
        </Box>
        {
            updateItemData && (<EditExternalSourceTypeModal
                open={true}
                item={updateItemData}
                handleClose={handleCloseEditItemModal}
                handleRefreshItems={handleRefreshItems}
            />)
        }
        {
            createItemModalOpen &&
            <AddExternalSourceTypeModal
                handleClose={handleCloseCreateItemModal}
                handleRefreshItems={handleRefreshItems}
                open={true}
            />
        }
        {deleteItem &&
            <ConfirmationModal
                title={"Delete External Source Type"}
                description={
                    <>
                        Are you sure you want to delete the <strong>{deleteItem.name}</strong> category?
                    </>
                }                 handleClose={handleCloseDeleteItemModal}
                handleSubmit={handleConfirmDeleteItem}
                loading={deleteItemApi.loading}
                open={true}
            />
        }
        {
            (deleteItems && !!Object.keys(selectedItems).length) && <ConfirmationModal
                title={"Delete Categories"}
                description={
                    <>
                        Are you sure you want to delete <strong>{Object.keys(selectedItems).length}</strong> category(ies)?
                    </>
                }                handleClose={handleCloseDeleteMultipleItemsModal}
                handleSubmit={handleDeleteMultipleItems}
                loading={deleteItemsApi.loading}
                open={true}
            />

        }
    </>

}

export default ExternalSourceTypes